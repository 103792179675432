import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { color } from '@src/settings';

type Props = {
	isActive: boolean,
	style: object,
	updateFunc: void,
}

const CrossHair: React.FC<Props> = props => (
	<Cont
		className={ classNames( props.className, props.isActive == true ? "isActive" : "" ) }
		style={ props.style }
		onClick={ props.updateFunc }
	/>
)

CrossHair.defaultProps = {
	isActive: false,
}

const Cont = styled.button`
	width: 3rem;
	height: 3rem;
	background-color: rgba(255,255,255,0.6);
	position: absolute;
	z-index: 0;
	border-radius: 100%;
	outline: none;
	transform: translate( -50%, -50% );
	transition: width 0.2s, height 0.2s, background-color 0.5s;
	&:before,
	&:after{
		content: "";
		display: block;
		height: 0.3rem;
		width: 1.2rem;
		background-color: ${ color.blue };
		position: absolute;
		top: 50%;
		left: 50%;
		transition: transform 0.1s linear;
	}
	&:before{
		transform: translate( -50%, -50% );
	}
	&:after{
		transform: translate( -50%, -50% ) rotate( 90deg );
	}
	&:hover{
		background-color: #FFF;
	}
	&.isActive{
		width: 4.5rem;
		height: 4.5rem;
		background-color: transparent !important;
		border: 0.3rem solid ${color.blue};
		z-index: 1;
		transition: width 0.2s, height 0.2s;
		&:before,
		&:after{
			opacity: 0;
			background-color: #FFF;
		}
		&:after{
			transform: translate( -50%, -50% ) rotate( 0deg );
		}
	}
`

export default CrossHair;
