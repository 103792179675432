import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import Image from 'gatsby-image';

import ProductViewer from '@components/products/product-viewer';
import Product_viewer_02 from '@components/products/product_viewer_02';
import Product_viewer_03 from '@components/products/product_viewer_03';
import Product_viewer_04 from '@components/products/product_viewer_04';
import Product_viewer_assembly from '@components/products/product_viewer_assembly';

import Hero from '@components/en/common/hero';
import PageIntro from '@components/common/page-intro';
import ContactIntro from '@components/en/common/contact-intro';

import Hero__mds from '@img/svg/products/hero__mds.svg';
import Img from 'gatsby-image';

import Layout from '@components/common/layout';
import { color } from '@src/settings';

const ProductsPage = ({ data }) => (
	<PageCont baseBgColor={ color.paleGray }>
		<Hero
			img={ data["hero_img_200414_2"] }
			title={<Hero__mds />}
		/>
		<PageIntro
			title="Adoption and Innovation of New Technology"
			subtitle="We manufacture automobile parts of various sizes and shapes, including Outer Panel parts, Shell Body parts, Under Body parts, Assembly parts, and Precision Machined parts. We also provide customized design and manufacturing of equipments or parts according to customer's request."
		/>
		<ProductViewer
			className="productViewer"
			title="BODY PARTS"
			lead="A group of parts that protect the passenger from impact with high strength and support the body structure"
			listNum={ 0 }
		/>
		<ProductViewer
			className="productViewer"
			title="BODY PARTS"
			lead="A group of parts that protect the passenger from impact with high strength and support performance with excellent body accuracy."
			listNum={ 1 }
		/>
		<ProductViewer
			className="productViewer"
			title="REAR UNDER PARTS"
			lead="A group of parts that make up the base frame, supports the safety and comfort of passenger"
			listNum={ 2 }
		/>
		<ProductViewer
			className="productViewer"
			title="ASSEMBLY PARTS"
			lead="An important group of parts that support functional vehicle parts by mounting functional parts."
			listNum={ 3 }
		/>
		<ProductViewer
			className="productViewer"
			title="OUTER PARTS"
			lead="A group of outer panel parts that create a beautiful body style"
			listNum={ 4 }
		/>
		<ContactIntro />
	</PageCont>
)

const PageCont = styled(Layout)`
	.productViewer{
		border-top: 1px solid #cdd1d6;
		border-bottom: 1px solid #cdd1d6;
	}
	.productViewer + .productViewer{
		border-top: none;
	}
`

export default ProductsPage

export const query = graphql`
	query ProductsImgs_en{
		hero_img_200414_2: file( relativePath: { eq: "products/hero-img_200414_2.jpg" }){
			...imgdata
		},
	},
	fragment imgdata on File{
	  childImageSharp {
	    fluid(quality: 90, maxWidth: 950) {
				...GatsbyImageSharpFluid_withWebp 
	    }
	  }
	}
`
