import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { color, breakpoint } from '@src/settings';

export const Cont = styled.div`
	display: flex;
	${ media.lessThan(`${ breakpoint.tb }px`)`
		display: block;
		height: auto;
	`}

	.cont{
		flex: 60% 0 0;
		display: flex;
		flex-direction: column;
	}
	.diagram{
		flex: auto 1 1;
	}
	.descs{
		flex: auto 0 0;
	}
	.intro{
		flex: 40% 0 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		background-color: #FFF;
		padding: 0 8.5rem;
		&__count{
			font-size: 1.3rem;
			line-height: 1;
			font-weight: 300;
			margin-bottom: 3rem;
			color: #CCC;
			span{
				display: inline-block;
				letter-spacing: 0.1em;
				color: #555;
				&:nth-child(1){
					margin-right: 0.5rem;
					color: ${color.blue};
				}
				&:nth-child(2){
					margin-left: 0.5rem;
					color: #CCC;
				}
			}
		}
		&__title{
			font-size: 2.2rem;
			font-weight: 300;
			line-height: 1.3;
			margin-bottom: 0.6rem;
		}
		&__subTitle{
			font-size: 1.3rem;
			line-height: 1.3;
			color: ${color.blue};
			margin-bottom: 2rem;
		}
		&__lead{
			font-size: 1.5rem;
			font-weight: 300;
			line-height: 1.9;
			white-space: pre-line;
		}
		${ media.lessThan(`${ breakpoint.tb }px`)`
			padding: 2.5rem;
			background-color: #546d8e;
			color: #FFF;
			&__count{
				span:nth-child(1){
					color: #FFF;
				}
			}
			&__subTitle{
				color: #FFF;
			}
		`}
	}
`;
