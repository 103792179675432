import React from 'react';
import CrossHair from '../cross-hair';
import { Cont, Box, Img } from './styles';

type Props = {
	className: string,
	img: any,
	updateFunc: void,
}

const Diagram: React.FC<Props> = props => {
	return (
		<Cont className={ props.className }>
			<Box>
			<Img
				className="img"
				fluid={ props.diagramImg.childImageSharp.fluid }
				fade={ true }
			/>
			{ props.list.map((item,index) => {
				return (
					<CrossHair
						className="crossHair"
						style={{ top: item.pos[0], left: item.pos[1] }}
						key={ index }
						isActive={ props.currentItem == index ? true : false }
						updateFunc={ () => props.updateFunc(index) }
					/>
				)
			})}
			</Box>
		</Cont>
	)
}

export default Diagram;
