import React from 'react';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';
import { useMeasure } from 'react-use';

import Desc from './desc';

import { color } from '@src/settings';


type Props = {
	currentItem: number | false,
	updateFunc: void,
	className: string,
}

const DescList: React.FC<Props> = props => {
	const [ measureRef, { height } ] = useMeasure();
	const transitions = useTransition( props.currentItem, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	})
	return (
		<Cont
			className={ props.className }
			descHeight={ height }
		>
			{ transitions.map(({ item: current, key, props: transitionProps }) => {
				return (
				<animated.div
					style={ transitionProps }
					key={ key }
					className="descWrap"
					ref={ measureRef }
				>
					<Desc
						//画像名をcamelCaseに変更して使用する('-'がgraphqlで使用できないため)
						img={ props.data[props.list[current]["img"].replace(/-(\w)/g, (v,$1) => $1.toUpperCase())] }
						name={ props.list[current].name }
						desc={ props.list[current].desc }
					/>
				</animated.div>
				)})}
				<nav className="Descs__nav Ctl">
				<button
					onClick={ () => 0 != props.currentItem ? props.updateFunc(props.currentItem-1) : props.updateFunc(props.list.length-1 ) }
					className="Ctl__arrow Ctl__prev"
					type="button"
				/>
				<button
					onClick={ () => props.list.length != props.currentItem+1 ? props.updateFunc(props.currentItem+1) : props.updateFunc(0)}
					className="Ctl__arrow Ctl__next"
					type="button"
				/>
			</nav>
		</Cont>
	)
}

DescList.defaultProps = {
	currentItem: 1,
}

const Cont = styled.div`
	position: relative;
	height: ${ props => props.descHeight }px;
	background-color: #fcfcfd;
	.descWrap{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}
	.Ctl{
		&__arrow{
			width: 5rem;
			position: absolute;
			top: 0;
			bottom: 0;
			outline: none;
			&:before{
				content: "";
				display: block;
				width: 1.5rem;
				height: 1.5rem;
				border-left: 0.5rem solid ${color.blue};
				border-bottom: 0.5rem solid ${color.blue};
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate( -50%, -50% ) rotate( 45deg);
			}
		}
		&__prev{
			left: 0;
		}
		&__next{
			right: 0;
			&:before{
				transform: translate( -50%, -50% ) scaleX( -1 ) rotate( 45deg );
			}
		}
	}
`

export default DescList;
