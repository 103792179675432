import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import Img from 'gatsby-image/withIEPolyfill';
import { color, breakpoint } from '@src/settings';

type Props = {
	img: React.ReactElement,
	name: string
}

const Desc: React.FC<Props> = props => (
	<Cont className="item isActive">
		<Img
			className="img"
			fluid={ props.img.childImageSharp.fluid }
			fade={ true }
		/>
		<div className="txt">
			<h3 className="name">{ props.name }</h3>
			{ props.desc && (
				<p className="desc">{props.desc}</p>
			)}
		</div>
	</Cont>
)

const Cont = styled.div`
	display: flex;
	z-index: 1;
	background-color: #f6f6f8;
	padding: 4rem 6rem;

	.img{
		flex: 18rem 0 0;
		background-size: cover;
		background-position: 50% 50%;
		margin-right: 3rem;
		&:before{
			content: "";
			display: block;
		}
	}
	.txt{
		flex: auto 1 1;
		align-self: center;
	}
	.name{
		font-size: 1.9rem;
		line-height: 1.3;
		font-weight: 400;
		color: ${color.blue};
	}
	.desc{
		font-size: 1.4rem;
		line-height: 1.7;
	}
	.name + .desc{
		margin-top: 0.8rem;
	}
	.item:not(.isActive){
		opacity: 0;
		visibility: hidden;
		z-index: 0;
		display: none;
	}
	.item.isActive{
		opacity: 1;
		visibility: visible;
		z-index: 1;
	}
	${ media.lessThan(`${ breakpoint.sp }px`)`
		padding: 2.5rem 6rem;
		.img{
			flex: 10rem 0 0;
			margin-right: 2rem;
		}
	`}
`

export default Desc;
