import React from 'react';
import styled from 'styled-components';
import ImgBase from 'gatsby-image';

export const Cont = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	background-color: #eceff3;
	position: relative;
	& > .crossHair{
		position: absolute;
		z-index: 1;
		transform: translate( -50%, -50% );
		&.isActive{
			z-index: 2;
		}
	}
`;

export const Box = styled.div`
	width: 100%;
	position: relative;
	&:before{
		content: "";
		display: block;
		padding-top: 55.55%;
	}
`;

export const Img = styled(ImgBase)`
	position: absolute !important;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;
