import React, { useState } from 'react';
import { connect } from 'react-redux';
import { StaticQuery } from 'gatsby';
import styled from 'styled-components';
import Diagram from './diagram';
import DescList from './desc-list';
import { color } from '@src/settings';

import src_ja from './product-list';
import src_en from './product-list_en';
import { Cont } from './styles';

type Props = {
	listNum: number,
	title: string | React.Fragment,
	subtitle: string | React.Fragment,
	lead: string | React.Fragment,
	data: object,
	lang: "ja" | "en",
}

const Base: React.FC<Props> = (props) => {
	const src = props.lang == "ja" ? src_ja : src_en;
	const [ currentItem, setCurrent ] = useState(0);
	const zeroPad = (num:number, length:number):void => {
		return ("0000000000" + num).slice(-length);
	}

	return (
		<Cont className={ props.className }>
			<div className="intro">
				<p className="intro__count"><span>{ zeroPad(props.listNum+1,2) }</span>/<span>{ zeroPad(src.length,2) }</span></p>
				<h3 className="intro__title">{ props.title }</h3>
				<p className="intro__subTitle">{ props.subtitle }</p>
				<p className="intro__lead">{ props.lead }</p>
			</div>{/* /.Viewer__intro */}

			<div className="cont">
				<Diagram
					className="diagram"
					list={ src[props.listNum]["item"] }
					currentItem={ currentItem }
					diagramImg={ props.data[src[props.listNum]["diagramImg"]] }
					updateFunc={ setCurrent }
				/>
				<DescList
					className="descs"
					list={ src[props.listNum]["item"] }
					currentItem={ currentItem }
					data={ props.data }
					updateFunc={ setCurrent }
				/>	
			</div>
		</Cont>
	);
}

const ProductViewer = props => (
	<StaticQuery
		query={ graphql`
			query productViewer{
				bodyparts01: file( relativePath: { eq: "products/bodyparts01.png" }){
					...imgdata
				},

				bodyparts02: file( relativePath: { eq: "products/bodyparts02.png" }){
					...imgdata
				},

				rearunder: file( relativePath: { eq: "products/rearunder.png" }){
					...imgdata
				},

				outerparts: file( relativePath: { eq: "products/outerparts.png" }){
					...imgdata
				},

				productViewer__impactBeamFront: file( relativePath: { eq: "products/product-viewer__impact-beam-front.jpg"}){
					...imgdata
				},

				productViewer__doorHingeSide: file( relativePath: { eq: "products/product-viewer__door-hinge-side.jpg"}){
					...imgdata
				},

				productViewer__impactBeamRear: file( relativePath: { eq: "products/product-viewer__impact-beam-rear.jpg"}){
					...imgdata
				},

				productViewer__boxFuelInlet: file( relativePath: { eq: "products/product-viewer__box-fuel-inlet.jpg"}){
					...imgdata
				},

				productViewer__transportHook: file( relativePath: { eq: "products/product-viewer__transport-hook.jpg"}){
					...imgdata
				},

				productViewer__bodyLowerBack: file( relativePath: { eq: "products/product-viewer__body-lower-back.jpg"}){
					...imgdata
				},

				productViewer__luggageCompartmentHinge: file( relativePath: { eq: "products/product-viewer__luggage-compartment-hinge.jpg"}){
					...imgdata
				},

				productViewer__hoodLock: file( relativePath: { eq: "products/product-viewer__hood-lock.jpg"}){
					...imgdata
				},

				productViewer__subAssyfrontFenderApron: file( relativePath: { eq: "products/product-viewer__sub-assyfront-fender-apron.jpg"}){
					...imgdata
				},

				productViewer__centerBodyPillarInner: file( relativePath: { eq: "products/product-viewer__center-body-pillar-inner.jpg"}){
					...imgdata
				},

				productViewer__centerBodyPillar: file( relativePath: { eq: "products/product-viewer__center-body-pillar.jpg"}){
					...imgdata
				},

				productViewer__frameSubAssyBackWindow: file( relativePath: { eq: "products/product-viewer__frame-sub-assy-back-window.jpg"}){
					...imgdata
				},

				productViewer__roofSideRail: file( relativePath: { eq: "products/product-viewer__roof-side-rail.jpg"}){
					...imgdata
				},

				productViewer__roofCenter: file( relativePath: { eq: "products/product-viewer__roof-center.jpg"}){
					...imgdata
				},

				productViewer__windshieldHeader: file( relativePath: { eq: "products/product-viewer__windshield-header.jpg"}){
					...imgdata
				},

				productViewer__memberSubAssyRearFloorSide: file( relativePath: { eq: "products/product-viewer__member-sub-assy-rear-floor-side_200515.jpg"}){
					...imgdata
				},

				productViewer__memberSubAssyRearFloorCrossNo2: file( relativePath: { eq: "products/product-viewer__member-sub-assy-rear-floor-cross-no2.jpg"}){
					...imgdata
				},

				productViewer__memberRearFloorCenterNo1: file( relativePath: { eq: "products/product-viewer__member-rear-floor-center-no1.jpg"}){
					...imgdata
				},

				productViewer__menberSubAssyRearFloorCrossNo3: file( relativePath: { eq: "products/product-viewer__menber-sub-assy-rear-floor-cross-no3.jpg"}){
					...imgdata
				},

				productViewer__memberSubAssyRearFloorCrossNo4: file( relativePath: { eq: "products/product-viewer__member-sub-assy-rear-floor-cross-no4.jpg"}){
					...imgdata
				},

				productViewer__memberSubAssyRearFloorCrossNo5: file( relativePath: { eq: "products/product-viewer__member-sub-assy-rear-floor-cross-no5.jpg"}){
					...imgdata
				},

				productViewer__hoodOuter: file( relativePath: { eq: "products/product-viewer__hood-outer.jpg"}){
					...imgdata
				},

				productViewer__hoodInner: file( relativePath: { eq: "products/product-viewer__hood-inner.jpg"}){
					...imgdata
				},

				productViewer__fender: file( relativePath: { eq: "products/product-viewer__fender.jpg"}){
					...imgdata
				},

				productViewer__sideOuter: file( relativePath: { eq: "products/product-viewer__side-outer.jpg"}){
					...imgdata
				},

				productViewer__trunkLidInner: file( relativePath: { eq: "products/product-viewer__trunk-lid-inner.jpg"}){
					...imgdata
				},

				productViewer__trunkLidCopper: file( relativePath: { eq: "products/product-viewer__trunk-lid-copper.jpg"}){
					...imgdata
				},

				productViewer__trunkLidOuter: file( relativePath: { eq: "products/product-viewer__trunk-lid-outer.jpg"}){
					...imgdata
				},

				productViewer__roofOuter: file( relativePath: { eq: "products/product-viewer__roof-outer.jpg"}){
					...imgdata
				},

				productViewer__braketComputerE: file( relativePath: { eq: "products/product-viewer__braket-computer-e.jpg"}){
					...imgdata
				},

				productViewer__bracketThrottleBody: file( relativePath: { eq: "products/product-viewer__bracket-throttle-body.jpg"}){
					...imgdata
				},

				productViewer__braketActuator: file( relativePath: { eq: "products/product-viewer__braket-actuator.jpg"}){
					...imgdata
				},

				productViewer__armLomerAssy: file( relativePath: { eq: "products/product-viewer__arm-lomer-assy.jpg"}){
					...imgdata
				},

				productViewer__neckAssyFiller: file( relativePath: { eq: "products/product-viewer__neck-assy-filler.jpg"}){
					...imgdata
				},

				productViewer__spindleRearAxle: file( relativePath: { eq: "products/product-viewer__spindle-rear-axle.jpg"}){
					...imgdata
				},
			}
			fragment imgdata on File{
				childImageSharp {
					fluid(quality: 90, maxWidth: 950) {
						...GatsbyImageSharpFluid_withWebp 
					}
				}
			}
		`}
		render={ data => (
			<Base data={ data } { ...props } />
		)}
	/>
)

const mapStateToProps = state => ({
	lang: state.langReducer.language,
})

export default connect(mapStateToProps)(ProductViewer);
